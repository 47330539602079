<template>
  <div id="wallet">
    <header>
      <p>智能匹配低息资金方，超3000万人已放款</p>
    </header>
    <section>
      <div class="borrow">
        <h2>借多少(元)</h2>
        <div class="howMuch">￥<input disabled type="number" v-model="borrowNum"></div>
        <p>单笔可借1000-200000元，请输入1000的整数倍</p>
        <div class="interest_rate">
          <div>日利率0.03% <span>0.06%</span><span>专享降息</span></div>
          <span>年华利率低至12%，借10000元1年可省662元</span>
        </div>
        <div class="select howLong ">
          <span>借多久</span>
          <div><input type="text" v-model="borrowTime" :readonly='true'> <i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="select city">
          <span>贷款城市</span>
          <div><input type="text" v-model="borrowCity" :readonly='true'> <i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
      <div class="product">
        <h2>匹配贷款产品</h2>
        <el-checkbox v-for="(item,index) in checkboxList" :key="index" :v-model="index"><img
            src="https://static.fangxin.plus/h5/old_h5/img/icon1.png" alt="">{{ item.product.htMechanismName + "|" + item.product.name }}
        </el-checkbox>
      </div>
    </section>
    <footer>
      <el-checkbox v-model="checked">
        <p>本人已阅读并同意</p>
        <i><a v-for="(item,index) in agreement" :key="index" @click="item.fun()">{{ item.decs }}<br/></a>
          <!-- <a v-for="(item,index) in xyList" :key="index" @click="tmpFun(item.agreement)">{{ item.mname }}<br/></a> -->
        </i>
      </el-checkbox>

      <button @click="next">立即提现
      </button>
    </footer>

    <div v-if="loading" style="position: fixed;top: 0;width: 100%; height: 100%; background: rgb(0 0 0 / 60%)">
      <div style="position: fixed; top: 45vh; align-items: center; width: 100%">
        <van-loading v-if="loading"/>
      </div>
    </div>
  </div>
</template>
<script>

import {Dialog, Toast} from "vant";
import {pushUserAssets,PushUserUrl} from "@/api/api";

export default {
  name: 'wallet',
  data() {
    return {
      fullscreenLoading: false,

      special:'',
      borrowNum: '',
      borrowTime: "",
      borrowCity: "",
      checkboxList: [],
      xyList: [],
      agreement: [
        {decs: '《个人信息授权共享协议》', fun: this.showService},
        // {decs: '《测试协议》', fun: this.tmpFun},
        // {decs: '《蓝尚鑫机构授权协议》', fun: this.tmpFun},
        // {decs: '《宏盛金融机构授权协议》', fun: this.tmpFun},
      ],
      checked: '',
      loading: false
    }
  },
  created() {
    if (window.sessionStorage.getItem("logined") !== "1") {
      this.$router.replace({path: '/'})
      Toast("未登录...")
      return
    }
    this.borrowTime = window.sessionStorage.getItem("loanTime") ? window.sessionStorage.getItem("loanTime") + "个月" : null;
    this.borrowCity = window.sessionStorage.getItem("city");
    this.borrowNum = window.sessionStorage.getItem("loanAmount");
    this.checkboxList = JSON.parse(window.sessionStorage.getItem("proList"))
    this.special = this.checkboxList[0].product.special;
    this.checkboxList.forEach(r => {
      this.xyList.push({
        mname: "《" + r.product.htMechanismName + "机构授权协议》",
        agreement: r.product.agreement,
      })
    })


    console.log("checkboxList:" + JSON.stringify(this.checkboxList))
    console.log("xyList:" + JSON.stringify(this.xyList))
  },
  methods: {

    showService: function () {
      Dialog.alert({
        messageAlign: 'left',
        height: '200px',
        confirmButtonColor: "#2a78fe",
        title: '用户注册服务协议',
        allowHtml: true,
        closeOnClickOverlay: true,
        message: "<div style='height:200px;'>《个人信息共享授权协议》(以下简称“本协议”)是由【成都盈拓数信信息科技有限公司】(以下简称“我们”)与用户(以下简称“您”)就个人信息共享所订立的有效合约，你通过本页面点击确认，即表示您同意接受本协议的全部约定内容以及本协议有关的已经发布或将来可能发布的各项规则、页面展示、操作流程、公告或通知（以下统称“规则”）。\n" +
            "\n" +
            "一、当您通过本页面使用我们提供的【第三方产品推荐】服务时，为了增加您的申请贷款通过机会，您的个人信息会进行共享。为了您的合法权益，请您在使用本服务前务必仔细阅读本协议的全部内容(特别是以粗体/下划线标注的内容)。如果您不同意本协议的任意内容，或者无法准确理解本协议任何条款的含义，请不要进行授权及后续操作。\n" +
            "\n" +
            "二、您同意本协议以数据电文形式订立。如您通过网络页面点击“同意”或“注册”或“申请”或“接受用户授权协议”(以网页显示为准)，即视为您已阅读理解本协议的全部内容并同意我们出于相关页面所述目的，将您的个人信息通过加密通道的方式提供给为您提供【账号注册、授信审核、贷款】等服务的其他个人信息处理者（以下简称“第三方”），账号能否注册成功、授信审核能否通过、能否申请贷款等相关服务以您和第三方的约定为准。\n" +
            "\n" +
            "三、本协议中共享的个人信息含个人敏感信息及其他个人信息，包括但不限于：\n" +
            "\n" +
            "1.您的身份信息，包括但不限于您的姓名、性别、年龄、户籍、现居住住址、电子邮箱、电话号码、身份证号、工作信息、学历、婚姻状况、紧急联系人、设备信息、生物特征、及通讯录等信息；\n" +
            "\n" +
            "2.您的信用信息，包括但不限于您的征信记录和信用报告；\n" +
            "\n" +
            "3.您的财产信息，包括但不限于您的财税信息、房产信息、车辆信息、基金、保险、股票、信托、债务、收入情况、银行卡号、开户行、开户地址等投资理财信息和负债信息等；\n" +
            "\n" +
            "4.其他实现服务所需的必要信息等。\n" +
            "\n" +
            "四、你同意并授权我们将您的上述信息共享给第三方，但我们与第三方在开展上述信息共享前，会严格要求第三方对在信息共享中所获取的您的上述信息予以保密并且依照相关法律法规或者本协议的约定使用您的上述信息。如果您不希望向第三方提供上述信息，请勿点击授权\n" +
            "\n" +
            "五、您理解，第三方服务由相应的第三方就以上服务及处理您个人信息的安全性独立承担责任。请您在授权同意前，仔细阅读并理解第三方的服务协议与隐私政策。因该第三方服务或其处理您的个人信息产生的纠纷，或第三方服务违反相关法律法规或者协议的约定，或您在使用第三方服务过程中遭受了损失，请您与第三方协商解决。\n" +
            "\n" +
            "六、您同意，凡因本协议引起的任何与之有关的争议、纠纷，首先通过友好协商的方式解决，如协商不能达成一致的，任何一方均可向本平台所在地有管辖权的人民法院提起诉讼。\n" +
            "\n" +
            "七、您同意本协议中的授权不可撤回或撤销\n" +
            "\n" +
            "八、本协议未尽事宜参照《用户注册服务协议》与《隐私政策》执行；若《用户注册服务协议》、《隐私政策》与本协议有冲突的，以本协议为准。\n" +
            "\n" +
            "用户姓名：【】\n" +
            "\n" +
            "用户账号：【】\n" +
            "\n" +
            "签署日期：【】</div>",
        theme: 'round-button',
      }).then(() => {
        // on close
      });
      this.checked = false
    },


    tmpFun: function (arg) {
      console.log(arg)
      window.open(arg)
    },

    next() {
      if (this.checked === "" || !this.checked) {
        this.$toast('请勾选协议');
        return
      }
      this.loading = true

      setTimeout(() => {
        if (this.checkboxList) {
          let num = 0;
          this.checkboxList.forEach(r => {
            if (num < 2) {

              if (this.special == 1) {
                PushUserUrl({ id: r.pushId, many: this.borrowNum }).then(res => {
                  num++
                  console.log(res.data);
                  if(res.data.code == 200){
                    window.location.href = res.data.data.data
                  }else{
                    this.$toast("系统错误")
                  }
                })
              } else {
                pushUserAssets({ id: r.pushId, many: this.borrowNum }).then(res => {
                  num++
                  console.log(res)
                  this.$router.replace({ path: '/Success' })
                })

              }
            }
          })
        }
      }, 2000)

    },
  }
}
</script>
<style lang="scss" scoped>
#wallet {
  width: 100vw;
  height: 100vh;
  text-align: left;
  position: relative;
  background: rgba(218, 234, 240, 0.2);

  header {
    padding: 10px 20px;
    background: rgb(133, 192, 228, .3);

    p {
      margin: 0;
      font-size: 14px;
      color: #2a78fe;
    }
  }

  section {
    .borrow {
      margin: 20px;
      padding: 15px;
      background: #FFF;
      border-radius: 15px;
      box-shadow: 0px 1px 10px rgb(214, 214, 214, 0.5);

      h2 {
        font-weight: 400;
        font-size: 18px;
        margin: 0;
      }

      .howMuch {
        font-size: 28px;
        display: flex;
        align-items: center;
        padding: 10px 0 5px 0;
        border-bottom: 1px solid #DFDFDF;

        input {
          width: 70%;
          border: 0;
          font-size: 30px;
          font-weight: 600;
          outline: none;
          background: none;
        }
      }

      p {
        color: #999;
      }

      .interest_rate {
        background: rgb(165, 165, 165, .3);
        border-radius: 5px;
        padding: 10px;

        div {
          margin-bottom: 10px;
          color: #2a78fe;
        }

        span {
          color: #999;
        }
      }

      .interest_rate div :first-child {
        text-decoration: line-through;
        margin: 0 5px;
      }

      .interest_rate div :last-child {
        padding: 2px 5px;
        color: #000;
        background: #999;
      }

      .select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        padding: 15px 0;

        input {
          font-size: 16px;
          outline: none;
          border: 0;
          text-align: right;
        }
      }

      .howLong {
        border-bottom: 1px solid #dfdfdf;
      }

      .city {
        padding: 15px 0 10px 0;
      }
    }

    .product {
      margin: 20px;
      background: #FFF;
      border-radius: 15px;
      box-shadow: 0px 1px 10px rgb(214, 214, 214, 0.5);
      display: flex;
      flex-direction: column;
      padding-bottom: 15px;

      h2 {
        padding: 15px;
        margin: 0;
        font-size: 17px;
        font-weight: 400;
        border-bottom: 1px solid #DFDFDF;
      }

      .el-checkbox {
        margin: 15px 0 0 15px;

        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
    }
  }

  footer {
    background: #fff;
    bottom: 0;
    padding: 15px;

    button {
      margin-top: 15px;
      width: 100%;
      padding: 15px;
      background: #2a78fe;
      color: #FFF;
      border-radius: 25px;
      border: 0;
      font-size: 16px;
    }

    p {
      margin: 0;
    }

    a {
      text-decoration: none;
      color: #2a78fe;
    }

    i {
      white-space: pre-wrap;
      font-style: normal;
    }
  }
}

#wallet footer ::v-deep.el-checkbox {
  display: flex;

  .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border-color: #2a78fe;
    z-index: 0;
  }

  .el-checkbox__inner::after {
    height: 7px;
    left: 5px;
    top: 3px;
    width: 4px;
  }

  .el-checkbox__label {
    display: flex;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }
}

#wallet section ::v-deep.el-checkbox {
  margin-top: 10px;
  display: flex;

  .el-checkbox__inner {
    width: 25px;
    height: 25px;
    border-radius: 50% !important;
  }

  .el-checkbox__inner::after {
    width: 5px;
    height: 12px;
    left: 8px;
    top: 3px;
    border: 0;
    border-bottom: 2px solid #FFF;
    border-right: 2px solid #FFF;
  }

  .el-checkbox__label {
    font-size: 16px;
    display: flex;
    line-height: 30px;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #3c75ff;
    border-color: #3c75ff;
  }
}

::v-deep .van-loading {
  text-align: center;
}
</style>
