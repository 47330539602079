<template>
  <div class="bg">
    <div>
      <div :class="{  'head_hidden'  : this.jlqd_status == false} " id="header">
        <div class="title clearfix">
          <img  class="logo" src="https://static.fangxin.plus/h5/newH5/img/LOGO.png" alt="">

          <div class="logo_title">
            <p  class="font_1">放薪&nbsp;|&nbsp;专业助贷</p>
            <p class="font_2">中国优质科技助贷平台</p>
          </div>

        </div>
      </div>
   

      <div class="main">
        <div class="p_1"><img src="https://static.fangxin.plus/h5/newH5/img/right.png" alt=""><span>初审成功</span></div>
        <div class="p_2" style="margin-top:20px;font-size: .4rem;">您已成功申请:&nbsp;<span
            style="color:#2a82e4;font-weight: bold;">{{product}}</span>
          <br>
          请您保持电话畅通，耐心等待助贷经理联系您办理！
        </div>
        <div class="p_3" style="margin-top:20px;font-size: .4rem;">

          <img class="" src="https://static.fangxin.plus/h5/newH5/img/successs.png" alt=""><span
            style="color:rgba(255, 141, 26, 1);font-size: .5rem;">重要提醒</span><br>
          在收到款项前，无论什么理由都不要向任何人转账支付费用。
        </div>
      </div>

    </div>
    <footer>
      <div v-if="jlqd_status" style="font-size:16px;color: rgba(166, 166, 166, 1.3)">FANGXIN&nbsp;放薪</div>
      <div style="font-size:12px;color: rgba(166, 166, 166,.5);vertical-align:middle;height: 20px;line-height: 20px;">
        <img style="width:20px;vertical-align:middle;" src="https://static.fangxin.plus/h5/newH5/img/icon2.png" alt="">
        保障你的信息安全
        <!-- 客服电话：400-656-8665<br> -->
        <!-- ICP备案号：蜀ICP备2022029731号-2<br> -->
      </div>

    </footer>
  </div>
</template>
<script>

export default {
  data() {
    return {
      product: '',
      jlqd_status:false,
      toH5: false,
      TIME_COUNT: 3,
      count: null,
    }
  },

  created() {
    this.getCode()
    this.product = JSON.parse(window.sessionStorage.getItem("proList"))[0].product.name;

  },

  methods: {
    countTime: function () {
      if (!this.timer) {
        this.count = this.TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 1 && this.count <= this.TIME_COUNT) {
            this.count--
          } else {
            this.count = "";
            this.show = true
            clearInterval(this.timer) // 清除定时器
            this.timer = null
            this.$router.replace("/Recommend")
          }
        }, 1000)
      }
    },
    getCode(){
     let a =  window.sessionStorage.getItem('toCode')
     if(a == "jlqd"){
      this.jlqd_status = false
     }else{
      this.jlqd_status = true
     }
    }
  }
}
</script>
<style lang="scss" scoped>
#header {
  display: flex;
  justify-content: center;
}

.bg {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  height: 100%;

  h2,
  p {
    margin: 0;
  }

  footer {
    height: 48px;
    width: 100%;
  }



  font-size: 16px;

  position: relative;
  width: 100%;
  background: linear-gradient(180deg, rgba(118, 156, 232, 1) 0%, rgba(118, 156, 232, 0) 70%);
  ;

  .title {
    width: 5.5rem;
    margin: 0 auto;
    display: flex;
    padding-top: 40px;
    justify-content: space-between;

    .logo {
      width: 1.4rem;
      height: 1.4rem;
    }

    .logo_title {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: #fff;

      .font_2 {
        font-size: .3rem;
        text-align: left;
      }

      .font_1 {
        font-size: .4rem;
        text-align: left;
      }
    }
  }

  .main {
    padding: 10px;
    box-sizing: border-box;
    background-color: #fff;
    width: 8rem;
    height: 300px;
    margin: 0 auto;
    margin-top: .8rem;
    border-radius: .2rem;
    padding-top: 20px;

    .p_1 {
      img {
        width: .7rem;
        height: .7rem;
        vertical-align: middle;
      }

      span {
        font-size: .5rem;
        vertical-align: middle;
        margin-left: 10px;
        font-weight: bolder;
      }
    }

    .p_2 {
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      padding: 10px;
    }

    .p_3 {
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      padding: 10px;
      font-size: .4rem;

      img {
        vertical-align: middle;
        width: .7rem;
      }

      ;
    }

  }

  .color2 {

    color: #2A82E4;
  }

  .font-1 {
    font-size: .4rem;
  }
  .head_hidden{
    visibility: hidden;
  }
  .clearfix:after {
    /*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .clearfix {
    *zoom: 1;
    /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
  }
}
</style>
